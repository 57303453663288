import { environment } from './../../../environments/environment';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring/error-monitoring.service';
import { HelperService } from './../../services/helper/helper.service';
import { UserDataService } from './../../services/user-data/user-data.service';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  eye = true;
  eyeOff = false;
  /** formulario de login */
  loginForm: UntypedFormGroup;
  /** formulario de registro */
  registroForm: UntypedFormGroup;
  /** Define si es login o registro */
  isLogin = true;
  /** indica que esta cargando */
  loadingButton = false;
  /** indica si el usuario valido terminos y condiciones */
  clientChecked = false;

  constructor(
    private userDataService: UserDataService,
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private router: Router,
    private navParams: NavParams,
    private alertController: AlertController,
    private errorMonitoringService: ErrorMonitoringService,
    private firebaseAnalytics: AngularFireAnalytics,
  ) {
    this.isLogin = this.navParams.get('openLogin');
    /* formulario de login */
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
    /* Formulario de registro */
    this.registroForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      surname: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobile: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
  }

  ngOnInit() {
    // registra el evento de analytics
    this.firebaseAnalytics.logEvent('viewLogin');
  }

  /** inicia sesión con usuario en firestore */
  login(): void {
    const credentials = this.loginForm.value;
    if (!credentials.email || !credentials.password) {
      return;
    }
    this.loadingButton = true;
    this.userDataService.emailLogin(credentials).then(
      (user) => {
        this.loadingButton = false;
        // registra evento en analytics
        this.firebaseAnalytics.logEvent('login', {
          method: 'email',
        });
        this.manageLogin();
      },
      (error) => {
        this.loadingButton = false;
        this.errorMonitoringService.sendError(error, 'login');
        this.manageLoginError(error);
      },
    );
  }

  /** se registra el usuario en authentication y
   * se crea el documento userProfile en firestore
   */
  public signUp(): void {
    const credentials = this.registroForm.value;
    credentials.email = credentials.email.toLowerCase();
    // tslint:disable-next-line: max-line-length
    const regExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* si no indican mobile */
    if (!credentials.mobile) {
      this.helperService.presentMessageAlert(
        'Mensaje',
        `El número de celular no es válido`,
      );
      return;
    }
    /* si el número de celular no tiene 10 digitos */
    if (credentials.mobile && credentials.mobile.toString().length !== 10) {
      this.helperService.presentMessageAlert(
        'Mensaje',
        `El número de celular no es válido`,
      );
      return;
    }
    /* Valida el email */
    if (!regExp.test(String(credentials.email).toLowerCase())) {
      this.helperService.presentMessageAlert(
        'Mensaje',
        `El email no es válido`,
      );
      return;
    }
    this.loadingButton = true;
    this.userDataService.emailRegister(credentials).then(
      (user) => {
        // registra evento en analytics
        this.firebaseAnalytics.logEvent('sign_up', {
          method: 'email',
        });
        // crea el documento userProfile en firestore
        this.userDataService
          .createProfile(credentials, user.user.uid)
          .then(() => {
            const settings = {
              url: environment.baseUrl,
            };
            /* Envia el mail de verificación */
            user.user.sendEmailVerification(settings).then(
              () => {},
              (error) => {
                this.errorMonitoringService.sendError(
                  error,
                  'sendEmailVerification',
                );
              },
            );
            this.loadingButton = false;
            this.manageLogin();
          })
          .catch((error) => {
            this.loadingButton = false;
            this.helperService.presentMessageAlert(
              'Mensaje',
              'No se creo tu perfil, inténtalo de nuevo',
            );
            this.errorMonitoringService.sendError(error, 'createProfile');
          });
      },
      (error) => {
        this.loadingButton = false;
        this.manageLoginError(error);
      },
    );
  }

  /**
   * @description se registra con el provider google
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-10-27
   * @returns {void}
   * @memberof LoginPage
   */
  googleLogin(): void {
    this.userDataService.googleLogin().then(
      (user) => {
        // si tiene el mail verificado
        if (user.user.emailVerified) {
          this.manageLogin();
        } else {
          const settings = {
            url: environment.baseUrl,
          };
          /* envía el mail de verificación */
          user.user.sendEmailVerification(settings).then(
            () => {
              this.helperService.presentMessageAlert(
                'Mensaje',
                'No has verificado tu cuenta, a tu correo se envió un link de verificación',
              );
              this.helperService.logout();
            },
            (error) => {
              this.helperService.presentMessageAlert('Mensaje', error.message);
              this.helperService.logout();
            },
          );
        }
      },
      (error) => {
        this.errorMonitoringService.sendError(error, 'googleLogin');
        this.manageLoginError(error);
      },
    );
  }

  /**
   * @description se registra con el provider facebook
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-10-27
   * @returns {void}
   * @memberof LoginPage
   */
  facebookLogin(): void {
    this.userDataService.facebookLogin().then(
      (usuario) => {
        // si tiene el mail validado
        if (usuario.user.emailVerified) {
          this.manageLogin();
        } else {
          const configuracion = {
            url: environment.baseUrl,
          };
          /* envía el mail de verificación */
          usuario.user.sendEmailVerification(configuracion).then(
            () => {
              this.helperService.presentMessageAlert(
                'Mensaje',
                'No has verificado tu cuenta, a tu correo se envió un link de verificación',
              );
              this.helperService.logout();
            },
            (error) => {
              this.helperService.presentMessageAlert('Mensaje', error.message);
              this.helperService.logout();
            },
          );
        }
      },
      (error) => {
        this.errorMonitoringService.sendError(error, 'facebookLogin');
        this.manageLoginError(error);
      },
    );
  }

  /** muestra el modal para digitar el correo
   * de reestablecimiento de contraseña y envia el email de
   * recuperación */
  async recoveryPassword(): Promise<any> {
    const alert = await this.alertController.create({
      header: 'Reestablecer contraseña',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Ingresa tu email',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Ok',
          handler: (data) => {
            // tslint:disable-next-line:max-line-length
            const regExp =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (regExp.test(String(data.email).toLowerCase())) {
              /* envia el email de recuperación */
              this.userDataService
                .recoveryPassword(data.email)
                .then(() => {
                  this.helperService.presentMessageAlert(
                    'Mensaje',
                    'Hemos enviado un link de restablecimiento a tu email',
                  );
                })
                .catch((error) => {
                  this.errorMonitoringService.sendError(
                    error,
                    'recoveryPassword',
                  );
                  if (error.code.includes('user-not-found')) {
                    this.helperService.presentMessageAlert(
                      'Mensaje',
                      'No encontramos ningún usuario con el email indicado',
                    );
                  } else {
                    this.helperService.presentMessageAlert(
                      'Mensaje',
                      'Ha ocurrido un error, inténtalo de nuevo',
                    );
                  }
                });
            } else {
              this.helperService.presentMessageAlert(
                'Mensaje',
                'Ingresa un email valido',
              );
            }
          },
        },
      ],
    });

    await alert.present();
  }

  /**
   * @description valida el formato del numero
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-10-27
   * @returns {void}
   * @memberof LoginPage
   */
  validateNumero(event: any): void {
    const newValue = event.target.value;
    const regExp = new RegExp('^[0-9]+$');
    if (!regExp.test(newValue)) {
      event.target.value = newValue.slice(0, -1);
    }
  }

  /**
   * @description retorna el mensaje adecuado de acuerdo
   * al error generado en el login o registro
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-10-27
   * @returns {void}
   * @memberof LoginPage
   */
  manageLoginError(error: any): void {
    switch (error.code) {
      case 'auth/user-not-found':
        this.helperService.presentMessageAlert(
          'Mensaje',
          'No hay ningún usuario asociado a este email',
        );
        break;
      case 'auth/wrong-password':
        this.helperService.presentMessageAlert(
          'Mensaje',
          'La contraseña es incorrecta',
        );
        break;
      case 'auth/invalid-email':
        this.helperService.presentMessageAlert(
          'Mensaje',
          'El email es invalido',
        );
        break;
      case 'auth/popup-closed-by-user':
        this.helperService.presentMessageAlert(
          'Mensaje',
          'La ventana fue cerrada, vuelve a inténtarlo',
        );
        break;
      case 'auth/email-already-in-use':
        this.helperService.presentMessageAlert(
          'Mensaje',
          'Ya hay un usuario con este email registrado',
        );
        break;
      case 'auth/account-exists-with-different-credential':
        this.helperService.presentMessageAlert(
          'Mensaje',
          'Te registraste con otro método de acceso, utiliza este para ingresar',
        );
        break;
      case 'auth/user-disabled':
        this.helperService.presentMessageAlert(
          'Alerta',
          'Tu usuario ha sido bloqueado. Sí crees es un error comunicate con soporte',
        );
        break;
      default:
        this.helperService.presentMessageAlert(
          'Mensaje',
          'Ocurrio un error, vuelve a inténtarlo',
        );
        this.errorMonitoringService.sendError(error, 'signUp');
        break;
    }
  }

  /**
   * @description redirige al usuario a la ruta adecuada
   * después del login o registro.
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-10-27
   * @returns {void}
   * @memberof LoginPage
   */
  manageLogin(): void {
    const data = {
      success_login: true,
    };
    const deniedUrl = this.userDataService.getDeniedUrl;
    // si el usuario intento acceder a una url pero no estaba logueado
    if (deniedUrl.length > 0) {
      // cierra el modal y envía los datos
      this.modalController.dismiss(data).then(() => {
        // redirige a la ruta
        this.router.navigate(deniedUrl).then(() => {
          const params = {
            message: 'Login exitoso',
            duration: 3000,
            position: 'bottom',
          };
          // muestra el mensaje de login exitoso
          this.helperService.presentToast(params);
          // limpia la url guardada
          this.userDataService.clearDeniedUrl();
        });
      });
      // si esta accediendo por primera vez
    } else {
      // cierra el modal y envía los datos
      this.modalController.dismiss(data).then(() => {
        const params = {
          message: 'Login exitoso',
          duration: 3000,
          position: 'bottom',
        };
        // muestra el mensaje de login exitoso
        this.helperService.presentToast(params);
      });
    }
  }

  /**
   * @description Muestra texto de contraseña
   *
   * @author Miguel Arrieta <miguel11281@gmail.com>
   * @date 2020-08-04
   * @returns {void}
   * @memberof LoginPage
   */
  showTextInput(): void {
    const camp: any = document.querySelector('.passw') as HTMLElement;
    camp.type = 'text';
    this.eye = false;
    this.eyeOff = true;
  }

  /**
   * @description Proteje el texto
   *
   * @author Miguel Arrieta <miguel11281@gmail.com>
   * @date 2020-08-04
   * @returns {void}
   * @memberof LoginPage
   */
  showPasswordInput(): void {
    const camp: any = document.querySelector('.passw') as HTMLElement;
    camp.type = 'password';
    this.eye = true;
    this.eyeOff = false;
  }

  /**
   * @description cierra el modal de login
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-10-27
   * @returns {void}
   * @memberof LoginPage
   */
  closeLogin(): void {
    const data = {
      success_login: false,
    };
    this.modalController.dismiss(data);
  }

  /** contacta a soporte */
  contactSupport(): void {
    this.firebaseAnalytics.logEvent('contactSupportLogin');
    window.open(
      `https://wa.me/${environment.miguelWhatsapp}?text=¡Buen día! Requiero soporte para iniciar sesión o registrarme`,
      'blank',
    );
  }
}
